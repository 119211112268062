<template>
  <div class="main_content program_emp_search align-items-center" style="min-height: 80vh">
    <div class="row justify-content-between mb_50">
      <div class="col-12 col-md-12 col-xl-3 col-xxl-5 top_heading">
        <div class="heading padd_ipad">
          <h2 style="color:#39353D;">Evaluators</h2>
          <h3 style="color:#39353D;" v-if="clientListPermission">PBE Management Dashboard</h3>
          <h3 style="color:#39353D;" v-else>Program Manager Dashboard</h3>
        </div>
      </div>
      <div class="col-12 col-md-12 col-xl-9 col-xxl-7">
        <div class="align-items-center d-flex justify-content-end w-100">
          <div class="date_toolbar_main">
            <div class="bg-white date-toolbar region_bar_add">
              <div class="row me-0">
                <div class="col-xs-12 col-md-12 col-lg-9 align-self-center">
                  <div class="">
                    <div class="bg-white date-toolbar" style="box-shadow: none">
                      <div class="row">
                        <div class="col-xs-12 col-md-12 align-self-center">
                          <div class="row">
                            <div class="col-xs-12 col-md-12 col-lg-6">
                              <div class="date">
                                <div class="heading_5">From</div>
                                <div class="text_black font_size_14 d-flex align-items-center">
                                  <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5"
                                      viewBox="0 0 17.5 17.5">
                                      <path data-name="Path 131"
                                        d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                        transform="translate(-2.25 -2.25)"
                                        style=" fill: none; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.5px; " />
                                    </svg>
                                  </span>
                                  <datepicker placeholder="MM DD, YYYY" v-model="listConfig.start_date"
                                    :format="customFormatter" @selected="changeStartDate"
                                    :disabledDates="disabledFutureDates"></datepicker>
                                  <p class="min-date-value d-none">
                                    {{ listConfig.start_date | formatDate }}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="col-xs-12 col-md-12 col-lg-6">
                              <div class="date todate pl_6">
                                <div class="heading_5">To</div>
                                <div class="text_black font_size_14 d-flex align-items-center">
                                  <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5"
                                      viewBox="0 0 17.5 17.5">
                                      <path data-name="Path 131"
                                        d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                        transform="translate(-2.25 -2.25)"
                                        style=" fill: none; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.5px; " />
                                    </svg>
                                  </span>
                                  <datepicker placeholder="MM DD, YYYY" v-model="listConfig.end_date"
                                    :format="customFormatter" @selected="changeEndDate" :disabled="isDisabled"
                                    :disabledDates="disabledDates"></datepicker>
                                  <p class="max-date-value d-none">
                                    {{ listConfig.end_date | formatDate }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-12 col-lg-3 align-self-center">
                  <div class="form-group for_ipad">
                    <v-select :reduce="(region_list) => region_list.id" :options="region_list" label="name"
                      placeholder="By Region" name="Region" v-model="listConfig.regionBy" v-validate="'required'"
                      @input="getRegionfilterData" browserAutocomplete="false" autocomplete="off" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a href="javascript:" class="reset_btn" @click="resetAll">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
              y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
              <metadata>
                Svg Vector Icons : http://www.onlinewebfonts.com/icon
              </metadata>
              <g>
                <path
                  d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z" />
              </g>
            </svg>
          </a>
        </div>
      </div>
    </div>

    <div class="row dataTables_wrapper no-footer" id="datatable_wrapper" v-show="clientListPermission">
      <div class="row justify-content-end align-items-center pb_40">
        <div class="col-md-12 col-xl-12 col-xxl-12">
          <div class="align-left">
            <h3 class="card_heading">Clients</h3>
          </div>
        </div>
      </div>
      <div class="
          col-xl-4 col-lg-3 col-md-12 col-xs-12
          text-md-center text-lg-start
          align-self-center
          my-2
        ">
        <div class="dataTables_length float_none" id="datatable_length">
          <label>Show
            <select name="datatable_length" aria-controls="datatable" class="" v-model.number="itemsPerPage"
              @change="getClientsBysearch">
              <option>10</option>
              <option>25</option>
              <option>50</option>
              <option>100</option>
              <option>500</option>
            </select>
            entries</label>
        </div>
      </div>
      <div class="col-xl-8 col-lg-9 col-md-12 col-xs-12">
        <div class="
            d-flex
            justify-content-lg-end justify-content-center
            align-items-center
            flex-wrap
          ">
          <div id="datatable_filter" class="dataTables_filter my-2">
            <label class="mb-0"><input type="search" class="" placeholder="Search" aria-controls="datatable"
                v-model="listConfig.search_string" v-on:keyup.enter="getClientsBysearch" />
              <a v-if="listConfig.search_string" href="javascript:void(0)" @click="getClientsBysearch"
                class="search_icon slty_up">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#7d8d9e" class="bi bi-search"
                  viewBox="0 0 16 16">
                  <path
                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </a>
              <a href="javascript:void(0)" v-if="listConfig.search_string" @click="
                listConfig.search_string = null;
              getClientsBysearch();
              " class="crossicon x_icon">
                X
              </a>
            </label>
          </div>
          <div class="visited_pages marg_left wdth_sts my-2">
            <div class="heading">
              <div class="form-group status_drop mb-0">
                <v-select @input="getClientsBysearch" :reduce="(status_list) => status_list.id" :options="status_list"
                  label="name" placeholder="All Status" name="Status" v-model="listConfig.search_status"
                  v-validate="'required'" />
                <small class="error text-danger" v-show="errors.has('addUserForm.Status')">
                  {{ errors.first("addUserForm.Status") }}
                </small>
              </div>
            </div>
          </div>
          <div class=" tab_report toggle_switch marg_left list_card_switch me-0 my-2 ">
            <ul id="myTab" role="tablist" class="nav nav-tabs list_tabs">
              <li role="presentation" class="nav-item list-item">
                <button id="percent-tab" data-bs-toggle="tab" data-bs-target="#percent" type="button" role="tab"
                  aria-controls="percent" aria-selected="true" class="nav-link list-link active">
                  List
                </button>
              </li>
              <li role="presentation" class="nav-item list-item">
                <button id="hash-tab" data-bs-toggle="tab" data-bs-target="#hash" type="button" role="tab"
                  aria-controls="hash" aria-selected="false" class="nav-link list-link">
                  Card
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div id="myTab2Content" class="tab-content" v-show="clientListPermission">
      <div id="percent" role="tabpanel" aria-labelledby="#percent" class="tab-pane fade active show">
        <div class="table_admin mt_50">
          <div class="col-md-12">
            <div class="table_card">
              <div class="table-responsive table_audit_log evaluator_table">
                <table class="table table-bordered table dataTable no-footer">
                  <thead>
                    <tr>
                      <th scope="col" width="20%" class="sort client_name_evaluator">
                        <SortIcons :lable="'CLIENT NAME'" :listConfig="listConfig" :sortBy="'company_name'"
                          @sorting="sorting($event)" />
                      </th>
                      <th scope="col" width="40%">WORK AREA</th>
                      <th scope="col" width="5%" class="status_col_evaluator move_icon">
                        <SortIcons :lable="'Status'" :listConfig="listConfig" :sortBy="'status'"
                          @sorting="sorting($event)" />
                        <svg style="margin-left: 10px;" v-tooltip="
                          'Active & Inactive'
                        " xmlns="http://www.w3.org/2000/svg" data-name="Group 13109" width="16" height="16"
                          viewBox="0 0 14 18.346">
                          <path data-name="Path 9426" d="M0 0h14v14H0z" style="fill: none"></path>
                          <path data-name="Path 9427"
                            d="M9 16a7 7 0 1 1 7-7 7 7 0 0 1-7 7zm0-1.4A5.6 5.6 0 1 0 3.4 9 5.6 5.6 0 0 0 9 14.6zm-.7-9.1h1.4v1.4H8.3zm0 2.8h1.4v4.2H8.3z"
                            transform="translate(-2 -1.654)" style="fill: rgb(109, 109, 109)"></path>
                        </svg>
                      </th>
                      <th scope="col" width="35%" class="text-center act_evaluator">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(client, indx) in clients_data" :key="indx">
                      <td>
                        <router-link class="hover_first_name" :to="{
                          name: 'evaluator_employee_search',
                          params: { id: client.u_id },
                        }">
                          <p>{{ client.name }}</p>
                        </router-link>
                      </td>
                      <td>
                        <p>{{ client.workArea }}</p>
                      </td>
                      <td>
                        <p>{{ client.status }}</p>
                      </td>
                      <td class="text-center">
                        <div class="edit_btn">
                          <p>
                            <router-link :to="{
                              name: 'evaluator_employee_search',
                              params: { id: client.u_id },
                            }">
                              <img :src="
                                require('@/assets/images/dashboard/View_Icon.svg')
                              " alt="img" class="view-icon" />
                            </router-link>
                            <!-- <a href="javascript:" aria-current="page"
                            class="view-dshboad-btn router-link-exact-active router-link-active" title="View">
                            <img :src="require('@/assets/images/dashboard/Edit_btn_table.svg')" alt="img"
                              class="view-icon">
                          </a> -->
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="data_length == 0">
                      <td colspan="4" class="text-center">No Records found</td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- cards  -->
      <div id="hash" role="tabpanel" aria-labelledby="#hash" class="tab-pane fade">
        <div class="group_cards mt-3">
          <div class="row">
            <div v-for="(client, indx) in clients_data" :key="indx" class="col-sm-12 col-md-6 col-lg-3">
              <router-link :to="{
                name: 'evaluator_employee_search',
                params: { id: client.u_id },
              }">
                <div class="white_box_group py-3">
                  <div class="row align-items-center justify-content-center justify-content-lg-start">
                    <!-- <div class="col-sm-2 col-2">
                      <div class="form-check ms-2 ms-lg-3">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                      </div>
                    </div> -->
                    <div class="col-3 col-sm-3 ps-2 ps-lg-4 px-0">
                      <img :src="client.clientLogo" class="img-fluid client_fit_image" width="48" alt="" />
                    </div>
                    <div class="col-sm-7 col-7 box_content px-0">
                      <h3 class="fit_text" v-tooltip="client.name">{{ client.name }}</h3>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div v-if="!clients_data.length" class="col-sm-12 col-md-12 col-lg-12">
              <h3 class="text-center no_data card_no_data white_card border_radius_20">
                No Records found
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class=" flex-wrap mt-4" v-show="clientListPermission">
      <p class="float-lg-start text-sm-center">{{ positionText }}</p>
      <VPagination v-if="data_length" class="dataTables_paginate" :totalPages="pages.length" :perPage="itemsPerPage"
        :currentPage.sync="currentPage" @pagechanged="onPageChange" />
    </div>
    <!-- Table 2 -->
    <div class="table_admin mt_70" v-show="reportsInReviewPermission">
      <div class="col-md-12">
        <div class="table_card">
          <div class="row justify-content-end align-items-center pb_40">
            <div class="col-md-12 col-xl-12 col-xxl-12">
              <div class="align-left">
                <h3 class="card_heading">Reports in Review</h3>
              </div>
            </div>
          </div>
          <div class="dataTables_wrapper no-footer">
            <div class="dataTables_length float-none float-lg-start text-center mb-0">
              <label>Show
                <select name="datatable_length_review" aria-controls="datatable" class="mb-0 mb-lg-3 mb-xl-0"
                  v-model.number="itemsPerPage_review" @change="getReviewBysearch">
                  <option>10</option>
                  <option>25</option>
                  <option>50</option>
                  <option>100</option>
                  <option>500</option>
                </select>
                entries</label>
            </div>
            <div
              class=" align-items-center align-items-md-baseline d-flex justify-content-center justify-content-lg-end flex-wrap flex-lg-nowrap float-none float-lg-end dataTables_filter no_btm_srch newHideFilter mt-4 mt-lg-0">
              <h1 v-if="reviewCheckbox.length" class="updatestatus ms-3">
                Update Status
              </h1>

              <label v-else><input type="search" placeholder="Search" class="" aria-controls="datatable"
                  v-model="listConfig_review.search_string" v-on:keyup.enter="getReviewBysearch" />

                <a v-if="listConfig_review.search_string" href="javascript:void(0)" @click="getReviewBysearch"
                  class="search_icon slty_up">
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#00" class="bi bi-search"
                    viewBox="0 0 16 16">
                    <path
                      d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </a>
                <a href="javascript:void(0)" v-if="listConfig_review.search_string"
                  @click="listConfig_review.search_string = null; getReviewBysearch(); " class="crossicon x_icon">
                  X
                </a>
              </label>
              <div class=" region_select status_box new_status status_select mb-5 mx_20 i_all_down">
                <v-select v-if="reviewCheckbox.length" class="select vs-drop-cust"
                  :reduce="(update_review_status) => update_review_status.id" :options="update_review_status"
                  label="name" dense hide-details single-line placeholder="All Status"
                  v-model="listConfig_review.review_status" @input="updateBulkStatus($event)" />
                <v-select v-else class="select vs-drop-cust" :reduce="(review_status) => review_status.id"
                  :options="review_status" label="name" dense hide-details single-line placeholder="All Status"
                  v-model="listConfig_review.review_status" @input="updateBulkStatus($event)" />
              </div>
              <div v-if="reviewCheckbox.length && oldSelectedCheckbox != 'Hidden'"
                class="region_select status_box new_status status_select mb-5">
                <a href="javascript:" class="comman_icons" @click="updateBulkStatus('Hidden')">
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16.975" viewBox="0 0 17 16.975">
                    <path id="Path_107" data-name="Path 107"
                      d="M11.431,15.437a7.874,7.874,0,0,1-8.974-5.364A7.836,7.836,0,0,1,3.685,7.693m4.6.714a2.358,2.358,0,0,1,3.335,3.335M8.289,8.406l3.334,3.334M8.291,8.407,5.705,5.821m5.92,5.92,2.586,2.586M2.884,3,5.705,5.821m0,0a7.862,7.862,0,0,1,11.752,4.252,7.879,7.879,0,0,1-3.247,4.253m0,0,2.821,2.821"
                      transform="translate(-1.457 -1.586)" fill="none" stroke="#a9a9a9" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="2" />
                  </svg>
                  <span class="ml_10"><b>Hide</b></span>
                </a>
              </div>
            </div>
          </div>
          <div class="table-responsive table_audit_log report_table evaluator_table">
            <table class="table table-bordered table dataTable no-footer">
              <thead>
                <tr>
                  <th style="min-width: 10px" class="sort sml_check">
                    <div class="form_check_box d-flex align-items-center mt-1">
                      <input type="checkbox" v-model="selectAll" aria-label="..." id="flexCheckDefault"
                        class="form-check-input ms-0" @change="checkSelected($event)" />
                      <label for="flexCheckDefault" class=""> </label>
                    </div>
                  </th>
                  <th scope="col" class="sort date_evaluator">
                    <SortIcons :lable="'Date of Eval'" :listConfig="listConfig_review"
                      :sortBy="'alchemer_summary_reports.date_of_evaluation'" @sorting="sorting_review($event)" />
                  </th>
                  <th scope="col" class="sort name_evaluator">
                    <SortIcons :lable="'First Name'" :listConfig="listConfig_review" :sortBy="'users.first_name'"
                      @sorting="sorting_review($event)" />
                  </th>
                  <th scope="col" class="sort name_evaluator">
                    <SortIcons :lable="'Last Name'" :listConfig="listConfig_review" :sortBy="'users.last_name'"
                      @sorting="sorting_review($event)" />
                  </th>
                  <th scope="col" class="sort email_evaluator">
                    <SortIcons :lable="'Email Address'" :listConfig="listConfig_review"
                      :sortBy="'alchemer_summary_reports.user_email'" @sorting="sorting_review($event)" />
                  </th>
                  <th scope="col" class="sort name_evaluator">
                    <SortIcons :lable="'Client'" :listConfig="listConfig_review" :sortBy="'clients.company_name'"
                      @sorting="sorting_review($event)" />
                  </th>
                  <th scope="col" class="sort name_evaluator move_icon">
                    <SortIcons :lable="'Status'" :listConfig="listConfig_review"
                      :sortBy="'alchemer_summary_reports.status'" @sorting="sorting_review($event)" />
                    <svg style="margin-left: 10px"
                      v-tooltip="'Re-Review, In Review, Declined, Finalized, Evaluator Reviewed, Evaluator QC Reviewed, Hidden & Pending Client Approval'"
                      xmlns="http://www.w3.org/2000/svg" data-name="Group 13109" width="16" height="16"
                      viewBox="0 0 14 18.346">
                      <path data-name="Path 9426" d="M0 0h14v14H0z" style="fill: none"></path>
                      <path data-name="Path 9427"
                        d="M9 16a7 7 0 1 1 7-7 7 7 0 0 1-7 7zm0-1.4A5.6 5.6 0 1 0 3.4 9 5.6 5.6 0 0 0 9 14.6zm-.7-9.1h1.4v1.4H8.3zm0 2.8h1.4v4.2H8.3z"
                        transform="translate(-2 -1.654)" style="fill: rgb(109, 109, 109)"></path>
                    </svg>
                  </th>
                  <th scope="col" width="10" class="action_evaluator">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(review, indx) in review_data" :key="indx">
                  <td style="min-width: 10px">
                    <div class="form_check_box d-flex align-items-center">
                      <input v-if="review.editStatus" type="checkbox" v-model="reviewCheckbox" :value="review.id"
                        aria-label="..." :id="'flexCheckDefault' + indx" class="form-check-input ms-0"
                        @change="checkSelected($event)" />

                      <input v-else type="checkbox" aria-label="..." class="form-check-input ms-0" :disabled="true" />

                      <label :for="'flexCheckDefault' + indx" class=""> </label>
                    </div>
                  </td>
                  <td>
                    <p>{{ review.date }}</p>
                  </td>
                  <td>
                    <p>{{ review.firstName }}</p>
                  </td>
                  <td>
                    <p>{{ review.lastName }}</p>
                  </td>
                  <td>
                    <p class="email_tag">
                      <a href="mailto:">{{ review.email }}</a>
                    </p>
                  </td>
                  <td>
                    <p>{{ review.client }}</p>
                  </td>
                  <!-- <td>
                    <p v-if="review.status == 'Re-Review'" class="blue_review">
                      {{ review.status }}
                    </p>
                    <p v-if="review.status == 'In Review'" class="gray_review">
                      {{ review.status }}
                    </p>
                    <p v-if="review.status == 'Evaluator Reviewed' || review.status == 'Evaluator QC Reviewed'"
                      class="orange_review">
                      {{ review.status }}
                    </p>
                    <p v-if="review.status == 'Finalized'" class="light_blue_review">
                      {{ review.status }}
                    </p>
                    <p v-if="review.status == 'Declined'" class="pink_review">
                      {{ review.status }}
                    </p>
                    <p v-if="review.status == 'Hidden'" class="black_review">
                      {{ review.status }}
                    </p>
                  </td> -->
                  <td>
                    <p v-bind:class="switchStatusColor(review.status)">
                      {{ review.status }}
                    </p>
                  </td>
                  <td>
                    <div class="edit_btn">
                      <p>
                        <a v-if="review.viewReport" @click="showReportDataSet(review.id)" data-bs-toggle="modal"
                          data-bs-target="#dataset" aria-current="page"
                          class=" view-dshboad-btn router-link-exact-active router-link-active evalutor_table_view "
                          title="Data set">
                          <img :src="require('@/assets/images/dashboard/Database.png')" alt="img"
                            class="view-icon" />
                        </a>
                        <a v-else href="javascript:"
                          @click="showNoPermissionMessage('You don\'t have permission to view this report')"
                          aria-current="page" data-bs-toggle="modal" data-bs-target="#permissionModal"
                          class=" view-dshboad-btn router-link-exact-active router-link-active evalutor_table_view "
                          title="View">
                          <img :src="require('@/assets/images/dashboard/Database.png')" alt="img"
                            class="view-icon" />
                        </a>
                        <router-link v-if="review.editReport" :to="{
                          name: 'view_edit_survey',
                          params: { url: review.u_id },
                        }" target="_blank" title="Edit Survey Report"
                          class="view-dshboad-btn router-link-exact-active router-link-active evalutor_table_edit">
                          <img class="edit-icon" :src="require('@/assets/images/dashboard/Edit_btn_table.svg')"
                            alt="img" />
                        </router-link>
                        <a v-else href="javascript:" aria-current="page"
                          @click="showNoPermissionMessage('You don\'t have permission to edit this report')"
                          data-bs-toggle="modal" data-bs-target="#permissionModal"
                          class="view-dshboad-btn router-link-exact-active router-link-active evalutor_table_edit"
                          title="Edit">
                          <img :src="require('@/assets/images/dashboard/Edit_btn_table.svg')" alt="img"
                            class="view-icon">
                        </a>
                        <a href="javascript:" aria-current="page" @click="allComments(review)" data-bs-toggle="modal"
                          data-bs-target="#commentModal"
                          class="router-link-exact-active router-link-active view-dshboad-btn evalutor_table_edit"
                          title="Add Comment">
                          <img :src="require('@/assets/images/dashboard/note.svg')" alt="img" class="edit-icon">
                        </a>
                        <a href="javascript:" @click="getSingleReportHistory(review.id)" aria-current="page" data-bs-toggle="modal" data-bs-target="#history" class=" router-link-exact-active router-link-active view-dshboad-btn " title="History" >
                          <img :src=" require('@/assets/images/dashboard/timer.png') " alt="img" class="edit-icon" />
                        </a>
                      </p>
                    </div>
                  </td>
                </tr>
                <tr v-if="data_length_review == 0">
                  <td colspan="7" class="text-center">No Records found</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="float-lg-start text-sm-center">{{ positionTextReview }}</p>
          <VPagination v-if="data_length_review" class="dataTables_paginate" :totalPages="pages_review.length"
            :perPage="itemsPerPage_review" :currentPage.sync="currentPage_review" @pagechanged="onPageChangeReview" />
        </div>
      </div>
    </div>

    <div class="modal fade box_up commentModal" id="commentModal" tabindex="-1" aria-labelledby="downloadLabel"
      aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="downloadLabel">
              <h3 class="heading_1">Eval Request Comments</h3>
            </h5>
            <button type="button" class="btn-close commentModalBtn" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body details_eval">
            <!-- <div v-if="allCommentArr.length">
              <div v-for="(comment, indx) in allCommentArr" :key="indx" class="row border-bottom mb-3">
                <div v-if="indx==0" class="col-md-12 font_size_16 border-bottom text-center mb-3">
                  <strong>There are some comments</strong>
                </div>
                <div class="col-md-3 font_size_14">
                  <strong>User Name: </strong> {{ comment.user_name }}
                </div>
                <div class="col-md-3 font_size_14">
                  <strong>User Email: </strong> {{ comment.user_email }}
                </div>
                <div class="col-md-6 font_size_14">
                  <strong>Comment: </strong> {{ comment.comments }}
                </div>
              </div>
            </div>
            <div v-if="!allCommentArr.length" class="row">
              <div class="col-xs-12 col-md-12">
                <h2 class="text-center mb-5">There are no comments</h2>
              </div>
            </div> -->
            <div v-if="allCommentArr.length">
              <div class="row mb-3">
                <div class="table table-responsive cmt_eva_table cust_scroll">
                  <table class="table" width="100%">
                    <tbody>
                      <tr>
                        <th style="width: 30%">Name</th>
                        <th style="width: 30%">Email</th>
                        <th style="width: 40%">Comment</th>
                      </tr>
                      <tr v-for="(comment, indx) in allCommentArr" :key="indx">
                        <td valign="middle" style="width: 30%">
                          {{ comment.user_name }}
                        </td>
                        <td valign="middle" style="width: 30%">
                          {{ comment.user_email }}
                        </td>
                        <td valign="middle" style="width: 40%">
                          {{ comment.comments }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div v-if="!allCommentArr.length" class="row">
              <div class="col-xs-12 col-md-12">
                <h2 class="text-center no_data mb-5">There are no comments</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-md-12">
                <div class="mb-3 form-group maxheight">
                  <div class="form-group">
                    <textarea class="form-control" aria-describedby="emailHelp" placeholder="Write your comment here"
                      v-model="add_comment" v-validate="'required'" v-on:keyup="countdown" maxlength="1000" />
                    <label class="text-end w-100">{{ remainingCount }} characters remaining</label>
                  </div>
                </div>
                <div v-if="add_comment.length" class="text-center mt_30">
                  <button @click="addComment" class="btn btn_primary mr_10" type="button">
                    Add Comment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Data Set Modal -->
    <div class="modal box_up fade" id="dataset" tabindex="-1" aria-labelledby="downloadLabel" aria-hidden="true"
      data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header download-btn-right">
            <h5 class="modal-title" id="downloadLabel">
              <h2 class="heading_2 font_size-20">Data Set</h2>
            </h5>
            <div class="down_cross">
              <a href="javascript:" class="ml_12 xl_download" v-if="this.dataSet" @click="exportDataset()">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                  <g data-name="Group 12689">
                    <path data-name="Path 114"
                      d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                      transform="translate(-3 -3)"
                      style=" fill: none; stroke: #a9a9a9; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; " />
                  </g>
                </svg>
                Download
              </a>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                @click="resetDataSet"></button>
            </div>

          </div>
          <Spinner v-show="!this.showDataset" ref="Spinner"></Spinner>
          <div class="modal-body details_eval">
            <!-- v-show="this.showDataset" -->
            <div>
              <div class="row justify-content-center main-timeline">
                <div class="col-xs-12 col-md-12 hei_col cust_scroll">
                  <div class="table table-responsive">
                    <table class="table historyTable" width="100%" align="center">
                      <tr>
                        <th>ID</th>
                        <th>QUESTION</th>
                        <th>ANSWER</th>
                      </tr>
                      <tr v-for="(dataValue, dataKey) in dataSet" :key="dataKey">
                        <td style="width:10%" v-html="dataValue.id"></td>
                        <td style="width:40%" v-if="dataValue.question != undefined"
                          v-html="dataValue.question.replace(/(<br ?\/?>|<hr ?\/?>|<label ?\/?>|<\/?span[^>]*>|<\/?div[^>]*>)+/gi, '')">
                        </td>
                        <td style="width:40%" v-else v-html="dataValue.question"></td>
                        <td style="width:50%" v-if="dataValue.answer != undefined"
                          v-html="dataValue.answer ? dataValue.answer.replace(/(<br ?\/?>|<hr ?\/?>|<label ?\/?>|<\/?span[^>]*>|<\/?div[^>]*>)+/gi, '') : 'Not Answered'">
                        </td>
                        <td style="width:50%" v-else v-html="dataValue.answer ? dataValue.answer : 'Not Answered'"></td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Access Denied Modal -->
    <div class="modal box_up fade" id="permissionModal" tabindex="-1" aria-labelledby="downloadLabel" aria-hidden="true"
      data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="downloadLabel">
              <h2 class="heading_2 font_size-20">Access Denied</h2>
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body details_eval">
            <div>
              <div class="row justify-content-center main-timeline-height" v-if="noPermissionMessage">
                <div class="col-xs-12 col-md-12">
                  {{ noPermissionMessage }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
    <HistoryModal v-bind:history="reportsHistory" v-bind:isShowHistory="showHistory" ref="historyModal"></HistoryModal>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import $ from "jquery";
import commonFunction from "../../mixin/commonFunction";
import VPagination from "@/components/VPagination";
import SortIcons from "@/components/SortingIcons";
import ConfirmDialogue from "@/components/ConfirmDialogue";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import Spinner from "../../components/Spinner.vue"
import exportFromJSON from "export-from-json";
import HistoryModal from "@/components/HistoryModal";
// import MobileSidebar from "../../components/MobileSidebar.vue"
import commonService from "../../services/commonService";

export default {
  name: "evaluator",
  mixins: [commonFunction],
  components: {
    VPagination,
    SortIcons,
    Datepicker,
    ConfirmDialogue,
    Spinner,
    HistoryModal,
    // MobileSidebar
  },
  data() {
    return {
      dataSet: [],
      showDataset: false,
      clientListPermission: false,
      reportsInReviewPermission: false,
      noPermissionMessage: "",
      show: false,
      disableInput: false,
      users: [],
      review_data: [],
      clients_data: [],
      selected: true,
      currentPage: 1,
      itemsPerPage: 10,
      listConfig: {
        offset: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
        sortBy: "company_name",
        order: "ASC",
        search_string: null,
        start_date: null,
        end_date: null,
        search_status: null,
        regionBy: null,
      },
      data_length: 0,

      currentPage_review: 1,
      itemsPerPage_review: 10,
      listConfig_review: {
        offset: (this.currentPage_review - 1) * this.itemsPerPage_review,
        limit: this.itemsPerPage_review,
        sortBy: "alchemer_summary_reports.date_of_evaluation",
        order: "DESC",
        search_string: null,
        review_status: null,
        start_date_review: null,
        end_date_review: null,
        regionBy_review: null,
      },
      data_length_review: 0,

      isDisabled: "",
      addPermission: false,
      delete_id: 0,
      delete_res: "",
      delete_reason: "",
      status_list: [
        {
          id: null,
          name: "All Status",
        },
        {
          id: "1",
          name: "Active",
        },
        {
          id: "0",
          name: "Inactive",
        },
      ],
      review_status : commonService.summaryReportStatus(),
      update_review_status: commonService.summaryReportUpdateStatus(),
      // review_status: [
      //   {
      //     id: null,
      //     name: "All Status",
      //   },
      //   {
      //     id: "Re-Review",
      //     name: "Re-Review",
      //   },
      //   {
      //     id: "In Review",
      //     name: "In Review",
      //   },
      //   {
      //     id: "Declined",
      //     name: "Declined",
      //   },
      //   {
      //     id: "Finalized",
      //     name: "Finalized",
      //   },
      //   {
      //     id: "Evaluator Reviewed",
      //     name: "Evaluator Reviewed",
      //   },
      //   {
      //     id: "Evaluator QC Reviewed",
      //     name: "Evaluator QC Reviewed",
      //   },
      //   {
      //     id: "Hidden",
      //     name: "Hidden",
      //   },
      // ],
      // update_review_status: [
      //   {
      //     id: "Re-Review",
      //     name: "Re-Review",
      //   }, {
      //     id: "In Review",
      //     name: "In Review",
      //   }, {
      //     id: "Declined",
      //     name: "Declined",
      //   }, {
      //     id: "Finalized",
      //     name: "Finalized",
      //   }, {
      //     id: "Evaluator Reviewed",
      //     name: "Evaluator Reviewed",
      //   }, {
      //     id: "Evaluator QC Reviewed",
      //     name: "Evaluator QC Reviewed",
      //   },
      // ],
      reviewCheckbox: [],
      dataArr: [],
      reviewCheckboxArr: {
        status: null,
        checkboxes: [],
      },
      requestDataArr: [],
      editUrlArr: {
        survey_id: null,
        session_id: null,
      },
      allCommentArr: [],
      add_comment: "",
      limitmaxCount: 1000,
      maxCount: 1000,
      remainingCount: 1000,
      hasError: false,
      commentArr: [],
      addCommentArr: {
        report_id: null,
        comments: null,
      },

      clearButton: true,
      disabledFutureDates: {
        from: new Date(Date.now()),
      },
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      isCheckAll: false,
      selectedUser: [],
      region_list: [],
      base_url: null,
      oldSelectedCheckbox: null,
      showHistory: false,
      reportsHistory: [],
      previousStartDate:null,
    };
  },
  computed: {
    selectAll: {
      get: function () {
        return this.review_data
          ? this.reviewCheckbox.length == this.review_data.length
          : false;
      },
      set: function (value) {
        var reviewCheckbox = [];
        if (value) {
          this.review_data.forEach(function (data) {
            if (data.editStatus) {
              reviewCheckbox.push(data.id);
            }
          });
        }
        this.reviewCheckbox = reviewCheckbox;
      },
    },
    btnText: function () {
      if (this.show) {
        return "";
      }
      return "";
    },
    positionText: function () {
      var endIndex = this.currentPage * this.itemsPerPage,
        startIndex = (this.currentPage - 1) * this.itemsPerPage + 1;
      if (this.data_length == 0) {
        startIndex = 0;
      }
      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.data_length ? this.data_length : endIndex) +
        " of " +
        this.data_length
      );
    },
    positionTextReview: function () {
      var endIndex_review = this.currentPage_review * this.itemsPerPage_review,
        startIndex_review =
          (this.currentPage_review - 1) * this.itemsPerPage_review + 1;
      if (this.data_length_review == 0) {
        startIndex_review = 0;
      }
      return (
        "Showing " +
        startIndex_review +
        " to " +
        (endIndex_review > this.data_length_review
          ? this.data_length_review
          : endIndex_review) +
        " of " +
        this.data_length_review
      );
    },
    pages() {
      if (this.data_length <= this.itemsPerPage) {
        return [1];
      }
      let pages_array = [
        ...Array(Math.ceil(this.data_length / this.itemsPerPage)).keys(),
      ].map((e) => e + 1);
      return pages_array;
    },
    pages_review() {
      if (this.data_length_review <= this.itemsPerPage_review) {
        return [1];
      }
      let pages_array_review = [
        ...Array(
          Math.ceil(this.data_length_review / this.itemsPerPage_review)
        ).keys(),
      ].map((e) => e + 1);
      return pages_array_review;
    },
  },
  watch: {
    currentPage(newValue) {
      this.listConfig.offset = (newValue - 1) * this.itemsPerPage;
      this.getClients();
    },
    currentPage_review(newValue_review) {
      this.listConfig_review.offset =
        (newValue_review - 1) * this.itemsPerPage_review;
      this.getReviewData();
    },
  },
  destroyed() {
    this.$parent.employeeDashboard = false;
  },
  beforeMount() {
    this.clientsForActivityRegion();
    // this.$parent.$refs.fullpageloader.is_full_page_loader = true;
    if (localStorage.getItem("userToken")) {
      window.addEventListener("scroll", this.scrollvue);
      // Store for check permission
      let res = this.$store.dispatch("auth/getAuthUser");
      res
        .then((value) => {
          let allPermission = value.user.roles.all_permission;
          if (allPermission.includes("evaluator-client-list")) {
            this.clientListPermission = true;
          }
          if (allPermission.includes("evaluator-reports-in-review")) {
            this.reportsInReviewPermission = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.tokenExpired();
    }
  },
  mounted() {
    document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
    this.base_url = process.env.VUE_APP_API_URL;
    this.listConfig = {
      offset: (this.currentPage - 1) * this.itemsPerPage,
      limit: this.itemsPerPage,
      sortBy: "company_name",
      order: "ASC",
      search_string: null,
      start_date: null,
      end_date: null,
      regionBy: null,
      search_status: null,
    };
    this.listConfig_review = {
      offset: (this.currentPage_review - 1) * this.itemsPerPage_review,
      limit: this.itemsPerPage_review,
      sortBy: "alchemer_summary_reports.date_of_evaluation",
      order: "DESC",
      search_string: null,
      review_status: null,
      start_date_review: null,
      end_date_review: null,
      regionBy_review: null,
    };
    this.getClients();
    this.getReviewData();
    if (this.$route.params.successMsg) {
      this.$toast.success(this.$route.params.successMsg, {
        position: "top-right",
        duration: 5000,
      });
    }
    if (this.$route.params.errorMsg) {
      this.$toast.error(this.$route.params.errorMsg, {
        position: "top-right",
        duration: 5000,
      });
    }
    this.$parent.employeeDashboard = true;
  },
  methods: {
    getSingleReportHistory(reportId) {
      if (reportId) {
        this.showHistory = false;
        var requestData = { reportId: reportId };
        if (localStorage.getItem("userToken")) {
          var _this = this;
          _this.$refs.historyModal.$refs.Spinner.modalLoader = true;
          let config = {
            method: "post",
            url: process.env.VUE_APP_API_URL + "/api/reports-all-history",
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "userToken"
              )}`,
            },
            data: {request_data : this.encodeAPIRequest(requestData)},
          };
          _this
            .axios(config)
            .then(({ data }) => {
              if (data.success) {
                var decodedJson = _this.decodeAPIResponse(data.data);
                _this.$refs.historyModal.$refs.Spinner.modalLoader = false;
                this.reportsHistory = decodedJson.records;
                this.showHistory = true;
              }
            })
            .catch(({ response }) => {
              _this.$refs.historyModal.$refs.Spinner.modalLoader = false;
              console.log(response);
            });
        } else {
          this.tokenExpired();
        }
      }
    },
    clientsForActivityRegion() {
      var _this = this;
      let config = {
        method: "get",
        url: process.env.VUE_APP_API_URL + "/api/clients-for-activity-region",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      };
      _this.axios(config)
        .then(({ data }) => {
          if (data.success) {
            var decodedJson = _this.decodeAPIResponse(data.records.data);
            
            _this.region_list.push({ id: 'ALL', name: 'ALL' });
            decodedJson.client_regions.forEach(function (items) {
              _this.region_list.push({ id: items.id, name: items.name });
            });
          }
          else {
            _this.region_list = [];
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    exportDataset() {
      var filterExport = [];
      var _this = this;
      var data;
      if (_this.dataSet) {
        Object.keys(_this.dataSet).forEach(key => {
          if (_this.dataSet[key].question != undefined) {
            filterExport.push({
              "Id": _this.dataSet[key].id,
              "Question": _this.dataSet[key].question.replace(/<[^>]*>?/gm, ''),
              "Answer": _this.dataSet[key].answer ? _this.dataSet[key].answer : "Not Answered",
            });
          }
        });
        data = filterExport;
      }
      const fileName = "report-dataset";
      const exportType = exportFromJSON.types.csv;
      //Audit log for download
      var requestData = {'section': "Reports In Review",'dashboard':'Evaluators','datasetMsg':"Dataset from 'Reports In Review'"};
        let config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/audit-log-for-download",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: this.encodeAPIRequestWithImages(requestData),
        };
        this.axios(config)
        .then(({ data }) => {
          console.log(data.message);
        });


      if (data) exportFromJSON({ data, fileName, exportType });
    },
    resetDataSet() {
      this.dataSet = [];
    },
    showReportDataSet(reportid) {
      var i = 1;
      /*for (const item in dataSet) {
        dataSet[item].id = i++;
        if(dataSet[item].options){
          for (const item1 in dataSet[item].options) {
            if (dataSet[item].options[item1].answer) {
              if (dataSet[item].answer)
                dataSet[item].answer +=
                  ", " + dataSet[item].options[item1].answer;
              else dataSet[item].answer = dataSet[item].options[item1].answer;
            }
          }
        }
        if (dataSet[item].subquestions) {
          for (const item2 in dataSet[item].subquestions) {
            if (dataSet[item].subquestions[item2].answer) {
              if (dataSet[item].answer) {
                dataSet[item].answer +=
                  ", " +
                  dataSet[item].subquestions[item2].question +
                  " : " +
                  dataSet[item].subquestions[item2].answer;
              } else {
                dataSet[item].answer =
                  dataSet[item].subquestions[item2].question +
                  " : " +
                  dataSet[item].subquestions[item2].answer;
              }
            }
          }
        }
      }
      this.dataSet = dataSet;
      this.noPermissionMessage = "";*/
      if (reportid) {
        this.showDataset = false;
        this.dataSet = [];
        var requestData = { 'reportid': reportid };
        if (localStorage.getItem("userToken")) {
          var _this = this;
          _this.$refs.Spinner.modalLoader = true;
          let config = {
            method: "post",
            url: process.env.VUE_APP_API_URL + "/api/reports-dataset",
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "userToken"
              )}`,
            },
            data: {request_data : this.encodeAPIRequest(requestData)},
          };
          _this
            .axios(config)
            .then(({ data }) => {
              if (data.success) {
                var decodedJson = _this.decodeAPIResponse(data.data);
                _this.$refs.Spinner.modalLoader = false;
                var dataSet = decodedJson.records;
                for (const item in dataSet) {
                  dataSet[item].id = i++;
                  if (dataSet[item].options) {
                    for (const item1 in dataSet[item].options) {
                      if (dataSet[item].options[item1].answer) {
                        if (dataSet[item].answer)
                          dataSet[item].answer +=
                            ", " + dataSet[item].options[item1].answer;
                        else dataSet[item].answer = dataSet[item].options[item1].answer;
                      }
                    }
                  }
                  if (dataSet[item].subquestions) {
                    for (const item2 in dataSet[item].subquestions) {
                      if (dataSet[item].subquestions[item2].answer) {
                        if (dataSet[item].answer) {
                          dataSet[item].answer +=
                            ", " +
                            dataSet[item].subquestions[item2].question +
                            " : " +
                            dataSet[item].subquestions[item2].answer;
                        } else {
                          dataSet[item].answer =
                            dataSet[item].subquestions[item2].question +
                            " : " +
                            dataSet[item].subquestions[item2].answer;
                        }
                      }
                    }
                  }
                }
                this.dataSet = dataSet;
                this.noPermissionMessage = "";
                this.showDataset = true;
              }
            })
            .catch(({ response }) => {
              _this.$refs.Spinner.modalLoader = false;
              this.dataSet = [];
              if (response.status == 401) {
                $('.modal-backdrop').remove()
                $(document.body).removeClass("modal-open");
                this.tokenExpired();
              }
            });
        } else {
          $('.modal-backdrop').remove()
          $(document.body).removeClass("modal-open");
          this.tokenExpired();
        }
      }
    },
    showNoPermissionMessage(msg) {
      this.noPermissionMessage = msg;
      this.dataSet = [];
      this.showDataset = true;
    },
    checkSelected(event) {
      if (event.target.checked) {
        this.listConfig_review.review_status = "";
      } else {
        if (this.reviewCheckbox.length == 0) {
          this.listConfig_review.review_status = this.oldSelectedCheckbox;
        }
      }
    },
    openEditForm(survey_id, session_id) {
      this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      this.editUrlArr.survey_id = survey_id;
      this.editUrlArr.session_id = session_id;
      var _this = this;
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/get-report-edit-url",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: this.editUrlArr,
      };
      _this
        .axios(config)
        .then(({ data }) => {
          this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          if (data.success) {
            var reportURL = data.edit_url;
            window.open(reportURL);
          }
        })
        .catch(({ response }) => {
          if (response.status == 401) {
            _this.$router.push({ name: "login" });
          }
        });
    },
    async updateBulkStatus($event) {
      if (this.reviewCheckbox.length && $event != null) {
        const confirmUpdate = await this.$refs.confirmDialogue.show({
          title: "Update Status",
          message: "Are you sure you want to update status?",
          okButton: "Update",
        });

        if (confirmUpdate) {
          this.dataArr = [];
          this.reviewCheckboxArr.status = $event;
          this.reviewCheckboxArr.checkboxes = this.reviewCheckbox;
          this.dataArr.push(this.reviewCheckboxArr);
          if (localStorage.getItem("userToken")) {
            var _this = this;
            _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
            let config = {
              method: "post",
              url: process.env.VUE_APP_API_URL + "/api/review-status-bulk-edit",
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem(
                  "userToken"
                )}`,
              },
              data: {request_data : this.encodeAPIRequest(this.dataArr)},
            };
            _this
              .axios(config)
              .then(({ data }) => {
                if (data.success) {
                  _this.$toast.success(data.message, {
                    position: "top-right",
                  });
                  this.listConfig_review.review_status = null;
                  this.reviewCheckbox = [];
                  this.getReviewBysearch();
                  this.selectAll = false;
                } else {
                  _this.$toast.error(data.message, {
                    position: "top-right",
                  });
                }
                _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
              })
              .catch(({ response }) => {
                if (response.status == 401) {
                  _this.$router.push({ name: "login" });
                } else {
                  _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                  _this.$toast.error(response.data.error, {
                    position: "top-right",
                    duration: 5000,
                  });
                }
              });
          } else {
            this.tokenExpired();
          }
        }


      } else {
        this.reviewCheckbox.length = 0;
        this.getReviewBysearch();
      }     
    },
    allComments(review) {
      this.add_comment = "";
      this.remainingCount = 1000;
      this.allCommentArr = [];
      this.fetchAllComments(review);
    },
    fetchAllComments(review) {
      if (review.id) {
        this.commentArr = [];
        this.addCommentArr.report_id = review.id;
        this.commentArr.push(this.addCommentArr);
        if (localStorage.getItem("userToken")) {
          var _this = this;
          let config = {
            method: "post",
            url:
              process.env.VUE_APP_API_URL + "/api/reports-review-all-comment",
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "userToken"
              )}`,
            },
            data: {request_data : this.encodeAPIRequestWithImages(this.commentArr)},
          };
          _this
            .axios(config)
            .then(({ data }) => {
              if (data.success) {
                var decodedJson = _this.decodeAPIResponse(data.data);
                this.allCommentArr = decodedJson.records;
              }
            })
            .catch(({ response }) => {
              if (response.status == 401) {
                _this.$router.push({ name: "login" });
              }
            });
        } else {
          this.tokenExpired();
        }
      }
    },
    addComment() {
      if (this.add_comment.length) {
        this.commentArr = [];
        this.addCommentArr.comments = this.add_comment;
        this.commentArr.push(this.addCommentArr);
        if (localStorage.getItem("userToken")) {
          var _this = this;
          _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
          let config = {
            method: "post",
            url:
              process.env.VUE_APP_API_URL + "/api/reports-review-add-comment",
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "userToken"
              )}`,
            },
            data: {request_data : this.encodeAPIRequestWithImages(this.commentArr)},
          };
          _this
            .axios(config)
            .then(({ data }) => {
              if (data.success) {
                _this.$toast.success(data.message, {
                  position: "top-right",
                });
                document.getElementsByClassName("commentModalBtn")[0].click();
              } else {
                _this.$toast.error(data.message, {
                  position: "top-right",
                });
              }
              _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            })
            .catch(({ response }) => {
              _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
              if (response.status == 401) {
                _this.tokenExpired();
              } else {
                _this.$toast.error(response.data.error, {
                  position: "top-right",
                  duration: 5000,
                });
              }
            });
        } else {
          this.tokenExpired();
        }
      }
    },
    countdown: function () {
      this.remainingCount = this.maxCount - this.add_comment.length;
      this.hasError = this.remainingCount < 0;
      if (this.hasError) {
        console.log("Haserror:", this.hasError);
        return;
      }
    },
    resetAll() {
      this.listConfig.end_date = "";
      this.listConfig.start_date = "";
      this.listConfig.regionBy = "";
      this.isDisabled = true;
      this.getClientsBysearch();
      this.getReviewBysearch();
    },
    onPageChange(page) {
      this.currentPage = page;
    },
    onPageChangeReview(pages) {
      this.currentPage_review = pages;
    },
    sorting($event) {
      this.listConfig.sortBy == $event.sortBy;
      this.listConfig.order == $event.order;
      this.getClients();
    },
    sorting_review($event) {
      this.listConfig_review.sortBy == $event.sortBy;
      this.listConfig_review.order == $event.order;
      this.getReviewData();
    },
    getRegionfilterData() {
      this.getClientsBysearch();
      this.getReviewBysearch();
    },
    getClientsBysearch() {
      this.currentPage = 1;
      this.listConfig.limit = this.itemsPerPage;
      this.getClients();
    },
    getReviewBysearch() {
      this.currentPage_review = 1;
      this.listConfig_review.limit = this.itemsPerPage_review;
      this.listConfig_review.start_date_review = this.listConfig.start_date;
      this.listConfig_review.end_date_review = this.listConfig.end_date;
      this.listConfig_review.regionBy_review = this.listConfig.regionBy;
      this.getReviewData();
    },
    getClients() {
      var _this = this;
      _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/clients-search-pagination",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: {request_data : this.encodeAPIRequestWithImages(_this.listConfig)},
      };
      _this
        .axios(config)
        .then(({ data }) => {
          var decodedJson = _this.decodeAPIResponse(data.data);
          _this.clients_data = decodedJson.clients;
          _this.data_length = decodedJson.counts;
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
        })
        .catch(({ response }) => {
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          _this.$toast.error(response.data.error, {
            position: "top-right",
            duration: 5000,
          });
          if (response.data.type == false) {
            _this.tokenExpired();
          }
        });
    },
    getReviewData() {
      var _this = this;
      _this.oldSelectedCheckbox = this.listConfig_review.review_status;
      _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/reports-in-review",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: {request_data : this.encodeAPIRequestWithImages(_this.listConfig_review)},
      };
      _this
        .axios(config)
        .then(({ data }) => {
          var decodedJson = _this.decodeAPIResponse(data.data);
          _this.review_data = decodedJson.review;
          _this.data_length_review = decodedJson.counts;
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
        })
        .catch(({ response }) => {
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          if (response.data.type == false) {
            _this.$router.push({
              name: "login",
            });
          }
        });
    },
    openModel(delete_user) {
      this.delete_reason = "";
      this.delete_res = "";
      this.delete_id = delete_user;
    },
    closeModel() {
      document.getElementsByClassName("btn-close")[0].click();
    },
    updateDatePicker() {
      // Extend dataTables search
      $.fn.dataTable.ext.search.push(function (settings, data) {
        var min = $(".min-date-value").html();
        var max = $(".max-date-value").html();
        var createdAt = data[0] || 0; // Our date column in the table
        if (
          min == "" ||
          max == "" ||
          (moment(createdAt).isSameOrAfter(min) &&
            moment(createdAt).isSameOrBefore(max))
        ) {
          return true;
        }
        return false;
      });
      this.getClientsBysearch();
      this.getReviewBysearch();
    },
    // changeStartDate() {
    //   this.$nextTick(() => {
    //     this.isDisabled = false;
    //     this.disabledDates = {
    //       to: new Date(this.listConfig.start_date - 8640000),
    //       from: new Date(Date.now()),
    //     };
    //     if (
    //       this.listConfig.end_date != "" &&
    //       this.listConfig.end_date != null
    //     ) {
    //       this.updateDatePicker();
    //     }
    //   });
    // },
    changeStartDate(newStartDate) {
      this.$nextTick(() => {
        const startDate = new Date(newStartDate);
        const endDate = this.listConfig.end_date ? new Date(this.listConfig.end_date) : null;

        if (endDate && startDate > endDate) {
          this.$toast.error('"From" date cannot be greater than the "To" date.', {
              position: "top-right",
              duration: 5000,
            });
          this.listConfig.start_date = this.previousStartDate;
        } else {
          this.previousStartDate = newStartDate;

          // Old changeStartDate code
          this.isDisabled = false;
          this.disabledDates = {
            to: new Date(this.listConfig.start_date - 8640000),
            from: new Date(Date.now()),
          };
          if (this.listConfig.end_date != "" && this.listConfig.end_date != null) {
            this.updateDatePicker();
          }
        }
      });
    },
    changeEndDate() {
      this.$nextTick(() => {
        this.updateDatePicker();
      });
    },
    customFormatter(date) {
      return moment(date).format("MMM DD, YYYY");
    },
  },
};
</script>